import React from 'react';
import styled from '@emotion/styled';

import Layout from '../components/layout';
import CallToAction from '../components/call-to-action';

const Content = styled('section')`
  min-height: calc(100vh - 12rem);
  padding: 2rem 0;

  p {
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) {
    padding: 2rem;
  }
`;

const Title = styled('h1')`
  font-size: 4rem;

  @media (min-width: 992px) {
    font-size: 6rem;
  }

  @media (min-width: 1200px) {
    font-size: 8rem;
  }
`;

const Page404 = () => (
  <Layout fullWidth={true}>
    <Content>
      <Title>Page not found</Title>
      <p>Ooops! The page you are trying to reach is not available.</p>
      <CallToAction to="/">Go back</CallToAction>
    </Content>
  </Layout>
);

export default Page404;
